import { Container, Grid } from 'semantic-ui-react';
import * as React from 'react';

interface Props {
  caption?: string;
}

export function CenteredImage(props: React.PropsWithChildren<Props>) {
  return (
    <Grid style={{ marginTop: '1ex', marginBottom: '1ex' }}>
      <Grid.Row centered>
        <Container>
          {props.children}
          {props.caption !== undefined && (
            <div style={{ fontSize: '85%' }}>
              <b>Figure:</b> {props.caption}
            </div>
          )}
        </Container>
      </Grid.Row>
    </Grid>
  );
}
