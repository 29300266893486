import * as React from 'react';
import { Link } from 'gatsby';
import { Container, Grid, Label, Message } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';

function AmbientNoiseMonitoring() {
  return (
    <>
      <H1>
        Studying Ambient Seismic Noise with Salvus:<br></br>
        Modeling, Monitoring and Inversion
      </H1>
      <p>
        Numerous studies have shown the great potential of utilizing ambient
        seismic noise from oceans or anthropogenic sources as a source of
        information.
        </p>
      <p>
        Conventional methods rely on cross-correlations of stacked noise
        observations to extract empirical Green's functions between station
        pairs.
      </p>
      <p>
        More recently, novel methods have been developed to treat the
        correlation function itself as an independent observable and to
        directly model the cross correlation wavefield. Among several
        theoretical and practical advantages, this approach shows great
        potential for joint reconstructions of noise sources and Earth
        structure as well as monitoring applications.
      </p>
      <p>
        This page summarizes the highlights of two case studies using Salvus
        as modeling engine for studying ambient seismic noise.
      </p>
      <H2>Case Study 1: Global-Scale Full-Waveform Ambient Noise Inversion</H2>
      <p>
        <a
            href="#references"
            target="_self"
        >
          Sager et al. (2020)
        </a>{' '}
      use Salvus to perform a joint full
      wave-form inversion for 3-D Earth structure and heterogeneous ambient
      seismic noise sources. By modeling the correlation wavefield, their
      approach is not limited by the theoretical requirements of Green's
      function retrieval.
      </p>
      <p>
      They obtain a heterogeneous noise source distribution of the Earth's hum
      and recover a velocity structure within the upper mantle that shows good
      agreement with S20RTS. This demonstrates the potential of full-waveform
      ambient noise inversion and its ability to complement passive data in
      regions with poor earthquake coverage.
      </p>

      <CenteredImage
        caption={[
        <i>(left){' '}</i>,
        "Reconstructed power spectral density distribution of the ",
        "Earth's hum in Northern Hemisphere winter obtain. Yellow regions ",
        "thus indicate where the Earth's hum is predominantly generated. ",
        <i>(right){' '}</i>,
        "Final inversion result for vertical shear velocity at 100 km depth.",
        <br></br>,
        <i>Source (figure and caption):{' '}</i>,
        <a href="https://doi.org/10.1029/2019JB018644" target="_blank">Sager et al. (2020){' '}</a>,
        "published under ",
        <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0.</a>
      ]}
      >
        <Grid doubling columns={2}>
          <Grid.Column>
            <StaticImage
              src="./resources/power_spectral_density.png"
              alt=""
              width={800}
              quality={100}
              placeholder="tracedSVG"
            />
          </Grid.Column>
          <Grid.Column>
            <StaticImage
              src="./resources/shear_velocity_100km.png"
              alt=""
              width={800}
              quality={100}
              placeholder="tracedSVG"
            />
          </Grid.Column>
        </Grid>
      </CenteredImage>

      <CenteredImage
        caption={[
          `
          Evolution of asymmetry and time shift measurements presented in
          histograms
          `,
          <i>(top right){' '}</i>,
          "and illustrated on selected correlation functions ",
          <i>(bottom panel){' '}</i>,
          ". The station map ",
          <i>(top left){' '}</i>,
          `
            shows the reference stations (stars) and receivers (triangles).
            They are color coded according to the three rows in the bottom panel
            (row 1: yellow, row 2: purple, row 3: green). The waveform plots
            focus on the surface wave signals on the causal and the acausal
            branches and have the part in between removed (indicated with dots).
            Values for asymmetry differences and time shifts are indicated in the
            waveform plots, in the center or on the corresponding branches.
            Red and blue correspond to the initial and final models,
            respectively, and observed correlations are plotted in black.
          `,
          <br></br>,
          <i>Source (figure and caption):{' '}</i>,
          <a href="https://doi.org/10.1029/2019JB018644" target="_blank">Sager et al. (2020){' '}</a>,
          "published under ",
          <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0.</a>
        ]}
      >
        <StaticImage
          src="./resources/correlation_waveform_fits.png"
          alt="Waveform fit of selected correlation functions."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <H2>Case Study 2: Fault monitoring using train traffic sources</H2>
      <p>
      The second case study by{' '}
        <a
            href="#references"
            target="_self"
        >
          Sager et al. (2021)
        </a>{' '}
        is about monitoring the San Jacinto Fault using train induced seismic waves.
        The authors use Salvus to model correlations between P-waves as well
        as their sensitivity to 3-D Earth structure.
      </p>
      <p>
        Similar to the first case study, considering the correlation wavefield
        as a self-consistent observable overcomes the requirements of Green's
        function retrieval. The results of this study pave the way for utilizing
        the sensitivity of body waves to temporal changes of the subsurface in
        monitoring applications.
      </p>

      <CenteredImage
        caption={[
          `
          The first step in the simulation of a correlation wavefield is to compute the generating wavefield
          `,
        <i>(left){' '}</i>,
        "with a source at x",
        <sub>2</sub>,
        `
        . In a simple, hypothetical medium two waves are excited: a fast type A and a slow type B. The resulting correlation wavefield
        `,
        <i>(right){' '}</i>,
        `
          contains all available wavefield interactions: AA, BB, AB, BA. Muting type B in the generating wavefield (indicated with ∗) allows us to focus on the remaining combinations AA and BA.
        `,
        <br></br>,
        <i>Source (figure and caption):{' '}</i>,
        <a href="https://doi.org/10.31223/X5WP6N" target="_blank">Sager et al. (2021){' '}</a>,
        "published under ",
        <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0.</a>
      ]}
      >
        <StaticImage
          src="./resources/correlation_wavefield.png"
          alt="Joint reconstruction of ambient noise sources and Earth."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <CenteredImage
        caption={[
          `
          Observed (black) and synthetic (red) correlations (filtered between 4 and 6 Hz) are compared for two station pairs: II.PFO - AZ.FRD
          `,
          <i>[A]{' '}</i>,
          "and II.PFO - AZ.CRY ",
          <i>[C]{'. '}</i>,
          `
          The complete data-independent structure sensitivity kernels for measurements of traveltime shifts in the indicated time windows are shown for both station pairs
          `,
          <i>[B & D]{'. '}</i>,
          `
          Since II.PFO and AZ.CRY are not perfectly aligned with the train location, the combination of the constituent kernels
          `,
          "reveals complex patterns. ",
          "The station locations are shown together with the train location in a map ",
          <i>[E]{'. '}</i>,
          "Station CI.IDO was used to detect the trains. ",
          <br></br>,
          <i>Source (figure and caption):{' '}</i>,
          <a href="https://doi.org/10.31223/X5WP6N" target="_blank">Sager et al. (2021){' '}</a>,
          "published under ",
          <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0.</a>
        ]}
      >
        <StaticImage
          src="./resources/sensitivity_kernels.png"
          alt="Waveform fit of selected correlation functions."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <H2>Benefits of Salvus for ambient noise monitoring</H2>
      <p>
      <ul>
        <li>
        Direct modeling of the correlation wavefield, which serves as independent observable.
        </li><li>
        Adjoint-based sensitivity analysis with support for various observables, including strain and gradients.
        </li><li>
        Full-waveform ambient noise inversion for joint reconstruction of ambient noise sources and Earth structure.
        </li><li>
        Modern Python-based user interface and extendable research platform.
        </li>
      </ul>
      </p>
      <H2>References</H2>
      <p>
      <ul>
        <li>
        <b>K. Sager et al. (2020):</b>{' '}
          <a
            href="https://doi.org/10.1029/2019JB018644"
            target="_blank"
          >
            Global‐scale full‐waveform ambient noise inversion.
          </a>{' '}
          <i>Journal of Geophysical Research: Solid Earth,</i> 125, e2019JB018644. doi:{' '}
          <a
            href="https://doi.org/10.1029/2019JB018644"
            target="_blank"
          >
            10.1029/2019JB018644
          </a>{' '}
        </li>
        <li>
          <b>K. Sager et al. (2021):</b>{' '}
          <a
            href="https://doi.org/10.31223/X5WP6N"
            target="_blank"
          >
            Modeling P waves in seismic noise correlations: Application to fault
            monitoring using train traffic sources.
          </a>{' '}
          <i>EarthArXiv.</i> doi:{' '}
          <a
            href="https://doi.org/10.31223/X5WP6N"
            target="_blank"
          >
            10.31223/X5WP6N
          </a>{' '}
        </li>
        </ul>
      </p>
      <p>
        Further reading:
        <ul>
        <li>
        <b>K. Sager et al. (2018):</b>{' '}
          <a
            href="https://doi.org/10.1029/2018JB016042"
            target="_blank"
          >
            Sensitivity of seismic noise correlation functions to global noise
            sources.
          </a>{' '}
          <i>Journal of Geophysical Research: Solid Earth,</i> 123,
          Pages 6911-6921. doi:{' '}
          <a
            href="https://doi.org/10.1029/2018JB016042"
            target="_blank"
          >
            10.1029/2018JB016042
          </a>{' '}
        </li>
        <li>
        <b>P. Paitz et al. (2019):</b>{' '}
          <a
            href="https://doi.org/10.1093/gji/ggy528"
            target="_blank"
          >
            Rotation and strain ambient noise interferometry.
          </a>{' '}
          <i>Geophysical Journal International,</i> Volume 216, Issue 3,
          March 2019, Pages 1938–1952. doi:{' '}
          <a
            href="https://doi.org/10.1093/gji/ggy528"
            target="_blank"
          >
            10.1093/gji/ggy528
          </a>{' '}
        </li>
        </ul>
      </p>
      <Grid style={{ marginTop: '5ex' }}>
        <Grid.Row centered>
          The Salvus software suite is Mondaic's flagship code to solve problems like
          the above
        </Grid.Row>
        <Grid.Row centered>
          ... and many more!
        </Grid.Row>
        <Grid.Row centered>
          <ContactUsFormModal />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default withLayout(AmbientNoiseMonitoring);
